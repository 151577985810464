import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { autoinject, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import QrScanner from "qr-scanner";
import { default as env } from "../../../../config/environment.json";

         

@autoinject
export class ScanQrCode {

  private camera: HTMLVideoElement;
  private qrScanner;
  private allowedUrl = env.front_front;
  private url: string = "";
  

  constructor(private yapilyHttpClient: YapilyHttpClient,
      private router: Router,
      private authService: AuthService) {
  }



  async attached() {
    try {
      this.qrScanner = new QrScanner(this.camera, this.handleScanQrCode);
      this.qrScanner.start();

    }
    catch (e) {
      console.log(e);
    }
  }

  handleScanQrCode = (stringQrCode: string) => {
    console.log(stringQrCode);
    if (
      stringQrCode.includes("BCD") &&
      stringQrCode.includes("002") &&
      stringQrCode.includes("SCT")
    ) {
      this.handleBankCase(stringQrCode);
    }

    if (
      stringQrCode.includes(this.allowedUrl) &&
      stringQrCode.includes("&comment=") &&
      stringQrCode.includes("&amount=")
    ) {
      this.handleBsvCase(stringQrCode);
    }

    if (
      stringQrCode.includes(this.allowedUrl) &&
      stringQrCode.includes("contacts/mine?")
    ) {
      this.handleContactCase(stringQrCode);
    }

    if (stringQrCode.includes("/surprise")) {
      this.handleSurpriseCase(stringQrCode);
    }

    else {
      alert("Url not allowed");
    }
  }

  handleBankCase(stringQrCode: string) {
    const qrCodeInfoArray = stringQrCode.split('\n').map((item) => item.trim());
    const displayName = qrCodeInfoArray[5];
    const iban = qrCodeInfoArray[6];
    const amount = qrCodeInfoArray[7].slice(3).trim();
    const reference = qrCodeInfoArray[9];
    this.url =
      env.front_front +
      "/payments?identification=" +
      iban +
      `&name=` + displayName +
      "&comment=" +
      reference +
      "&amount=" +
      amount +
      "&currency=EUR";
    window.location.href = this.url;
  }

  handleBsvCase(stringQrCode: string) {
    window.location.href = stringQrCode;
  }

  handleContactCase(stringQrCode: string) {
    window.location.href = stringQrCode;
  }

  handleSurpriseCase(stringQrCode: string) {
    window.location.href = stringQrCode;
  }



  unbind() {
    this.qrScanner.stop();
  }

  goBack() {
      this.router.navigateToRoute('home')
  }
}
